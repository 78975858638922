import React, { useState } from 'react';
import Square from './Square';
import 'bootstrap/dist/css/bootstrap.min.css';
import { squares } from './List';
import "./App.css"

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('all');

  const filteredSquares = squares.filter(square =>
    (square.alt.toLowerCase().includes(searchTerm.toLowerCase()) && (category === 'all' || square.category === category))
  );

  const buttonStyle = {
    padding: '0.25rem 0.5rem',
    fontSize: '0.85rem',
    textTransform: 'capitalize',
  };

  const categories = ['all', 'kitchen gadgets', 'puzzle/game', 'organizing tools', 'travel', 'car tools', 'cleaning gadgets', 'kids', 'k-pods', 'bathroom decor', 'beauty tools', 'random'];
  const sortedCategories = ['all'].concat(categories.slice(1).sort());

  return (
    <div className="App d-flex justify-content-center m-0 p-0 vh-100">
      <div className="search m-3 w-75 p-2 rounded">
        <div className="d-flex justify-content-center mb-3">
          <a href="https://www.tiktok.com/@dorysfinds?_t=8laNXvdqJwz&_r=1" target="_blank" rel="noopener noreferrer" className='socials'>
            <img src="https://img.icons8.com/material/96/tiktok.png" alt="TikTok" style={{ width: 50, height: 50 }} />
          </a>
          <h2 className='p-2 pb-1 text-center'>Dory's Finds</h2>
          <a href="https://www.instagram.com/dorysfinds?igsh=MXN1dnJobGw3MWVkNA==" target="_blank" rel="noopener noreferrer" className='socials'>
            <img src="https://img.icons8.com/fluency/96/instagram-new.png" alt="Instagram" style={{ width: 50, height: 50 }} />
          </a>
        </div>
        <input
          type="text"
          placeholder="What are you looking for?"
          className="form-control mb-3 border-primary border-1 rounded"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="btn-group mb-3 d-flex justify-content-center flex-wrap">
          {sortedCategories.map((cat) => (
            <button key={cat}
              className={`btn ${category === cat ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => setCategory(cat)}
              style={buttonStyle}>{cat}</button>
          ))}
        </div>
        <div className='d-flex flex-wrap justify-content-center align-items-center'>
          {filteredSquares.map(square => (
            <Square key={square.id} imageUrl={square.imageUrl} linkUrl={square.linkUrl} name={square.name} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
