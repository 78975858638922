export const squares = [
    { id: 1, imageUrl: 'https://m.media-amazon.com/images/I/612032FYk2L._AC_SL1500_.jpg', name:"Mechanical Can Opener", category:"kitchen gadgets", alt: "Mechanical Can Opener", linkUrl: 'https://amzn.to/3U0JD8A' },
    { id: 2, imageUrl: 'https://m.media-amazon.com/images/I/611jBV3NizL._AC_SL1500_.jpg', name:"Kurig K-pod Tray",category:"kitchen gadgets", alt: "Kurig K-pod Tray", linkUrl: 'https://amzn.to/49eO97W' },
    { id: 3, imageUrl: 'https://m.media-amazon.com/images/I/71R7xCJ1lNL._AC_SL1500_.jpg', name:"Electric Warming Tray", category:"kitchen gadgets", alt: "Electric Warming Tray", linkUrl: 'https://amzn.to/4ag36Ij' },
    { id: 4, imageUrl: 'https://m.media-amazon.com/images/I/81-5rAHBhTL._AC_SL1500_.jpg', name:"Book Nook", category:"puzzle/game", alt: "Book Nook, Bookend, 3D Puzzle, Library", linkUrl: 'https://amzn.to/3xsQ4IE' },
    { id: 5, imageUrl: 'https://m.media-amazon.com/images/I/61GTWnWgCHL._AC_SL1500_.jpg', name:"Label Maker", category:"organizing tools", alt: "label maker", linkUrl: 'https://amzn.to/43G3MUJ' },
    { id: 6, imageUrl: 'https://m.media-amazon.com/images/I/81mcxTkg4sL._AC_SL1500_.jpg', name:"Book Nook", category:"puzzle/game", alt: "Book Nook, Bookend, 3D Puzzle, Garden House", linkUrl: 'https://amzn.to/49i5YTC' },
    { id: 7, imageUrl: 'https://m.media-amazon.com/images/I/71xJmHUrrYL._AC_SL1500_.jpg', name:"Tide Pen", category:"travel", alt: "Tide To Go Instant Stain Remover Liquid Pen, travel", linkUrl: 'https://amzn.to/3TEj4Vo' },
    { id: 8, imageUrl: 'https://m.media-amazon.com/images/I/71eDAxhdpeL._AC_SL1500_.jpg', name:"Phone Holder for Cars", category:"car tools", alt: "MagSafe Car Mount iPhone Holder", linkUrl: 'https://amzn.to/4ama7qR' },
    { id: 9, imageUrl: 'https://m.media-amazon.com/images/I/71MSOdahrlL._AC_SL1500_.jpg', name:"Bissel Steam Shot", category:"cleaning gadgets", alt: "Bissel Steam Shot Handheld Hard Surface Steam Cleaner", linkUrl: 'https://amzn.to/4akr7Ol' },
    { id: 10, imageUrl: 'https://m.media-amazon.com/images/I/81kBz2X3sbL._AC_SL1500_.jpg', name:"Vegetable Chopper/Mandoline", category:"kitchen gadgets", alt: "Vegetable Chopper Vegetable Cutter Kitchen Mandoline Vegetable Slicer", linkUrl: 'https://amzn.to/4cTRZ9E' },
    { id: 11, imageUrl: 'https://m.media-amazon.com/images/I/71rkjTx4oAL._AC_SL1500_.jpg', name:"Keurig Coffee Maker", category:"kitchen gadgets", alt: "Keurig K-Supreme Single Serve K-Cup Pod Coffee Maker, With MultiStream Technology, Black", linkUrl: 'https://amzn.to/4alzNE9' },
    { id: 12, imageUrl: 'https://m.media-amazon.com/images/I/81DifQPg7rL._AC_SL1500_.jpg', name:"Disposable Placemats ", category:"kids", alt: "Disposable Placemats for Baby & Toddlers, Disposable Baby Placemats for Restaurants, travel", linkUrl: 'https://amzn.to/3U8ZFgQ' },
    { id: 13, imageUrl: 'https://m.media-amazon.com/images/I/81uE9OAFaOL._AC_SL1500_.jpg', name:"Timothy's Chai Latte", category:"k-pods", alt: "Timothy's Chai Latte K-Cup Coffee Pods, keurig", linkUrl: 'https://amzn.to/3vCeYVX' },
    { id: 14, imageUrl: 'https://m.media-amazon.com/images/I/81Y7dxITHVL._AC_SL1500_.jpg', name:"Folgers Hazelnut Cream Flavoured Coffee", category:"k-pods", alt: "Folgers Hazelnut Cream Flavoured Coffee, Single-Serve K-Cup Pods For Keurig Coffee Makers,", linkUrl: 'https://amzn.to/43ZX5Ny' },
    { id: 15, imageUrl: 'https://m.media-amazon.com/images/I/91zBUhFKfWL._AC_SL1500_.jpg', name:"Bissell Little Green Proheat Portable Deep Cleaner", category:"cleaning gadgets",alt: "Bissell Little Green Proheat Portable Deep Cleaner/Spot Cleaner with self-Cleaning HydroRinse Tool for Carpet and Upholstery", linkUrl: 'https://amzn.to/3xo8IkY' },
    { id: 16, imageUrl: 'https://m.media-amazon.com/images/I/61Lj0VYw5kL._AC_SL1024_.jpg', name:"Magnetic Dry Erase Whiteboard", category:"kitchen gadgets", alt: "Magnetic Dry Erase Whiteboard Sheet for Kitchen Fridge with Stain Resistant", linkUrl: 'https://amzn.to/3PShhuT' },
    { id: 17, imageUrl: 'https://m.media-amazon.com/images/I/61q0XTzrFML._AC_SL1500_.jpg', name:"Bathtub Soap Dish", category:"bathroom decor", alt:"Colorful Ceramic Mini Clawfoot Bathtub Soap Dish Decortative Soap Dish Small Planter Makeup Organizer Container Hamster Bathtub", linkUrl: 'https://amzn.to/4cIDfKu' },
    { id: 18, imageUrl: 'https://m.media-amazon.com/images/I/71AX90dSh9L._AC_SL1500_.jpg', name:"Hoover Oxy Spot and Stain Remover", category:"cleaning gadgets", alt: "Hoover Oxy Spot and Stain Remover, 22oz Pretreat Spray Formula for Carpet and Upholstery,", linkUrl: 'https://amzn.to/3JhO0pu' },
    { id: 19, imageUrl: 'https://m.media-amazon.com/images/I/71lI87w9hjL._AC_SL1500_.jpg', name:"Pimple Patches", category:"beauty tools", alt: "KEYCONCEPTS Pimple Patches for Face (120 Patches), Hydrocolloid Patch with Tea Tree Oil, Pimple Patch Zit Patch and Pimple Stickers - Hydrocolloid Acne Patches for Face - Zit Patches - Blemish Patches", linkUrl: 'https://amzn.to/3PWVanb' },
    { id: 20, imageUrl: 'https://m.media-amazon.com/images/I/71oqCE2ql+L._AC_SL1500_.jpg', name:" Ant Killer Baits", category:"random", alt: "Raid Max Double Control Liquid Ant Killer Baits and Traps, Kills Ants Where They Breed, For Indoor Use, Child Resistant, 8 Bait Stations", linkUrl: 'https://amzn.to/3VQA8Ks' },
  ];