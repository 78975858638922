import React from 'react';
import "./App.css"

function Square({ imageUrl, linkUrl, name }) {
  return (
    <a href={linkUrl} target="_blank" rel="noopener noreferrer" 
       className="square d-inline-flex flex-column align-items-center justify-content-center m-2 p-2 rounded text-decoration-none shadow">
      <div className="squarePic p-2 m-2 rounded" style={{backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
      <div>
        <p className="tag text-center p-1 pb-1">{name}</p>
      </div>
    </a>
  );
}

export default Square;
